<!-- 虚拟账号完善资料页面 -->
<template>
    <div class="login flex-align" v-wechat-title="`双优云桥-智慧研修平台`">
        <ul class="header flex-align-between">
            <li>
                <a href="https://news.ys.cnuen.net/#/home" target="_blank" rel="noopener noreferrer">
                    <img src="@/assets/images/home/cun_new_logo_copy.png" alt />
                </a>
            </li>
        </ul>
        <div class="form_box">
            <div class="title">完善信息</div>
            <el-form v-if="step == 1" :model="form" :rules="rules" ref="ruleForm" label-width="100px"
                :label-position="labelPosition">
                <el-form-item label="姓名:" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="性别:" prop="sex">
                    <el-select v-model="form.sex" placeholder="请选择性别">
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号:" prop="phone">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="身份证号:" prop="idcard">
                    <el-input v-model="form.idcard"></el-input>
                </el-form-item>
                <el-form-item label="地区:" prop="address">
                    <el-cascader :options="address" :props="{ expandTrigger: 'hover', checkStrictly: true }"
                        @change="handleChange" :value="form.address"></el-cascader>
                </el-form-item>
                <el-form-item label="学校:" prop="school">
                    <el-select v-model="form.school" placeholder="请选择学校">
                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in schoolList"
                            :key="index"></el-option>
                    </el-select>
                    <span class="notFind" @click="notFind">找不到学校？</span>
                </el-form-item>
                <el-form-item label="学科:" prop="subject">
                    <el-select v-model="form.subject" placeholder="请选择学科" filterable>
                        <el-option :label="item.dictLabel" :value="item.dictCode" v-for="(item, index) in subject"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="职务:" prop="position">
                    <el-select v-model="form.position" placeholder="请选择职务">
                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in position" :key="index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="组别:" prop="userGroup">
                    <el-select v-model="form.userGroup" placeholder="请选择组别">
                        <el-option :label="item.label" :value="item.id" v-for="(item, index) in userGroup" :key="index">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <div style="text-align: center">
                    <el-button type="primary" @click="onSubmit('ruleForm')">确定</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import {
    mapState,
    mapMutations
} from "vuex";
export default {
    data() {
        return {
            dialogVisible: false,
            step: 1,
            labelPosition: 'right',
            form: {
                name: "",
                sex: '',
                phone: '',
                idcard: '',
                address: [],
                // userGroup: '',
                position: '',
                subject: '',
                school: ''
            },
            form2: {
                psd: '',
                psd_confirm: "",
            },
            // userGroup: [],
            position: [],
            subject: [],
            schoolList: [],
            userId: '',
            phone: '',
            address: [],
            rules: {
                name: [{
                    required: true,
                    message: '请输入姓名',
                    trigger: 'blur'
                }],
                sex: [{
                    required: true,
                    message: '请选择性别',
                    trigger: 'change'
                }],
                phone: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                },
                {
                    pattern: /^1[3456789]\d{9}$/,
                    message: '手机号格式不对',
                    trigger: 'blur'
                }
                ],
                idcard: [{
                    required: true,
                    message: '请输入身份证',
                    trigger: 'blur'
                }, {
                    pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                    message: '请输入正确的身份证号码',
                    trigger: 'blur'
                }],
                address: [{
                    required: true,
                    message: '请选择地区',
                    trigger: 'change'
                }],
                school: [{
                    required: true,
                    message: '请选择学校',
                    trigger: 'change'
                }],
                subject: [{
                    required: true,
                    message: '请选择学科',
                    trigger: 'change'
                }],
                position: [{
                    required: true,
                    message: '请选择职务',
                    trigger: 'change'
                }],
                // userGroup: [{
                //     required: true,
                //     message: '请选择组别',
                //     trigger: 'change'
                // }],
            },
            account: ''
        }
    },
    methods: {
        ...mapMutations({
            setToken: 'getToken',
            setFileToken: 'getFileToken',
            setUserInfo: 'getUserInfo',
            setRoleList: 'getUserRoleList',
            setIsAdmin: 'getIsAdmin'
        }),
        // 选择地址
        handleChange(value) {
            console.log(value);
            this.form.address = value
            // this.form.address = JSON.stringify(value)
        },
        // 完善资料确定
        async onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid:', valid)
                if (valid) {
                    let data = {
                        tempToken: this.$route.query.tempToken,
                        name: this.form.name,
                        sex: this.form.sex,
                        phone: this.form.phone,
                        idCard: this.form.idcard,
                        area: JSON.stringify(this.form.address),
                        schoolId: this.form.school,
                        subjectId: this.form.subject,
                        userGroupId: 2766,
                        positionId: this.form.position,
                        projectId: 390,
                        account: this.account
                    }
                    this.$Api.Login.improveInfoCloud(data)
                        .then(resData => {
                            console.log(resData);
                            console.log('完善资料:', resData);
                            if (resData.code == 500) {
                                this.$message.error(resData.msg);
                                return
                            }
                            if (resData.code == 200) {
                                console.log('调用waitReturnValue方法')
                                this.setToken(resData.data.access_token)
                                this.waitReturnValue()
                            }

                        })
                        .catch(err => {
                            console.log('err:', err)
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        onCancel() {
            console.log('取消');
            this.$router.go(-1)
        },
        // 虚拟账号获取信息
        async getOption() {
            let data = {
                tempToken: this.$route.query.tempToken,
                projectId: 275,
            }
            let resData = await this.$Api.Login.getOptionCloud(data);
            console.log('虚拟账号获取信息:', resData);
            // this.userGroup = resData.data.userGroup;
            this.position = resData.data.position;
            this.subject = resData.data.subject;
            this.address = resData.data.area;
            this.schoolList = resData.data.school;
            this.form.name = resData.data.thirdUser.name;
            this.form.address = [resData.data.thirdUser.province, resData.data.thirdUser.city, resData.data.thirdUser.area];
            this.form.idcard = resData.data.thirdUser.idcard;
            this.account = resData.data.thirdUser.account ? resData.data.thirdUser.account : '';
            if (resData.data.thirdUser.gender == 2) {
                console.log('不显示性别')
            }else{
                this.form.sex = resData.data.thirdUser.gender;
            }
            if (resData.data.thirdUser.schoolInfo) {
                this.form.school = resData.data.thirdUser.schoolInfo.id;
            }
            if (resData.data.thirdUser.phone.includes("*")) {

            } else {
                this.form.phone = resData.data.thirdUser.phone
            }
        },
        // 等待接口完成
        waitReturnValue() {
            let userInfo = this.getUserinfo();
            let roles = this.getRoles();
            let fileToken = this.getFileToken();
            let isAdmin = this.getUserIsAdmin();
            Promise.all([userInfo, roles, fileToken, isAdmin]).then((values) => {
                // console.log(values);
                this.$notify.success({
                    title: "登陆成功",
                    duration: 2500
                });
                if (window.qt && Cookies.get('token')) {
                    this.$router.push(Cookies.get('path'));
                    Cookies.remove('path')
                } else {
                    console.log('跳转/item-list')
                    this.$router.push("/item-list");
                }
            }).catch((reason) => {
                console.log(reason);
            });
        },
        // 获取用户信息
        async getUserinfo() {
            let resData = await this.$Api.Home.userInfo();
            // console.log(resData);
            if (JSON.stringify(resData.data) == '{}') {
                this.$message.error('获取个人信息失败，请联系管理员');
                this.$store.dispatch('resetState');
                return;
            }
            this.setUserInfo(resData.data);
            return new Promise((resolve, reject) => {
                resolve(resData)
            })
        },
        // 获取用户身份
        async getRoles() {
            let resData = await this.$Api.Home.getroles();
            if (resData.data.length <= 0) {
                this.$message.error('获取个人信息失败，请联系管理员');
                this.$store.dispatch('resetState');
                return;
            }
            this.setRoleList(resData.data);
            return new Promise((resolve, reject) => {
                resolve(resData)
            })
        },
        // 获取文件预览token 
        async getFileToken() {
            let resData = await this.$Api.Resources.getFileToken();
            this.setFileToken(resData.data.token)
            return new Promise((resolve, reject) => {
                resolve(resData)
            })
        },
        // 查询登录用户是否为管理员(云端答疑的管理员身份)
        async getUserIsAdmin() {
            let resData = await this.$Api.Login.isAdmin();
            this.setIsAdmin(resData.data)
            return new Promise((resolve, reject) => {
                resolve(resData)
            })
        },
        // 找不到学校
        notFind() {
            this.$alert('010-88512948', '请联系以下电话', {
                confirmButtonText: '确定',
            });
        }
    },
    mounted() {
        this.getOption()
    },
};
</script>
<style lang='less' scoped>
@import url("./less/update_userInfo.less");
</style>